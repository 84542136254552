import docReady from '../../../../assets/js/docReady';

let firstPress = false;
let secondPress = false;

let firstPressNextclick = false;
let secondPressNextclick = false;

function doRedirect() {
    const pageType = document.querySelector('body').getAttribute('data-e-type');
    const pageId = document.querySelector('body').getAttribute('data-e-id');

    if (pageType && pageId) {
        window.open(`/admin/entries/${pageType}/${pageId}`);
    }
}

function doRedirectNextClick() {
    const currentUrl = window.location.href;
    window.open(`https://nextclick.keuze.nl/analyze/index?url=${currentUrl}`);
}

function doRedirectLongread() {
    if (typeof window.longreadUrl !== 'undefined') {
        window.open(window.longreadUrl);
    }
}

window.addEventListener('keyup', (keyEvent) => {
    if (keyEvent && (keyEvent.code === 'F6' || keyEvent.code === 'F7' || keyEvent.code === 'F8')) {
        if (firstPress && !secondPress) {
            secondPress = true;
        } else if (secondPress) {
            firstPress = false;
            secondPress = false;

            if (keyEvent.shiftKey) {
                doRedirectLongread();
            } else {
                doRedirect();
            }
        } else {
            firstPress = true;
            window.setTimeout(() => {
                firstPress = false;
                secondPress = false;
            }, 750);
        }
    }

    if (keyEvent && (keyEvent.code === 'F10' || keyEvent.code === 'F11')) {
        if (firstPressNextclick && !secondPressNextclick) {
            secondPressNextclick = true;
        } else if (secondPressNextclick) {
            firstPressNextclick = false;
            secondPressNextclick = false;
            doRedirectNextClick();
        } else {
            firstPressNextclick = true;
            window.setTimeout(() => {
                firstPressNextclick = false;
                secondPressNextclick = false;
            }, 750);
        }
    }
});

// Ugly bugfix to work around Safari webp support issues
function hasWebP() {
    const elem = document.createElement('canvas');

    if (elem.getContext && elem.getContext('2d')) {
        // was able or not to get WebP representation
        return elem.toDataURL('image/webp').indexOf('data:image/webp') === 0;
    }

    // very old browser like IE 8, canvas not supported
    return false;
}

docReady(() => {
    if (!hasWebP()) {
        // Remove all srcset's - yes, it's an ugly fix:
        document.querySelectorAll('img').forEach((image) => {
            if (image.hasAttribute('srcset')) {
                // Check whether the srcset contains WEBP:
                if (image.getAttribute('srcset').includes('webp')) {
                    image.removeAttribute('srcset');
                    image.removeAttribute('sizes');
                }
            }
        });
    }
});
